import React, { useState } from "react"
import { Descriptions } from "antd"
import axios from "axios"
import _ from "lodash"
import Interweave from "interweave"
import { useSearchParam, useMount } from "react-use"
import { formatMoney } from "utils"
import { COLOR_MAP, UserIdText } from "./styledComponents"

const DescriptionItem = Descriptions.Item
const { blue, red, gray } = COLOR_MAP

const ITEM_LIST = [
  { label: "投注单号", key: "settleId" },
  { label: "ServerID", key: "serverId" },
  { label: "游戏", key: "gameDetail.gameName" },
  { label: "房间 / 台号", key: "roomName" },
  {
    label: "总输赢",
    key: "winScore",
    render: winScore => (
      <>
        <span style={{ color: red }}>{formatMoney(winScore)}</span> 元
      </>
    )
  },
  {
    label: "余额",
    key: "latestScore",
    render: latestScore => `${formatMoney(latestScore)} 元`
  },
  { label: "结算时间", key: "updateTime", render: updateTime => updateTime?.split(".")?.[0] || updateTime, span: 2 },
  {
    label: "玩家信息",
    key: "gameDetail.userIds",
    render: (userIds, data) => {
      return (
        <>
          {userIds.map((userId, index) => {
            const isUser = userId === data.userId

            return (
              <UserIdText key={index} color={isUser ? red : blue}>
                {userId}
              </UserIdText>
            )
          })}
          <div style={{ color: gray }}>(红色标注的帐号表示此注单为该名玩家所产生)</div>
        </>
      )
    },
    span: 2
  },
  {
    label: "详情",
    key: "gameDetail.details",
    render: details => {
      return details.map((detail, index) => {
        return (
          <div key={index} style={{ minHeight: "1em" }}>
            <Interweave content={detail} />
          </div>
        )
      })
    },
    span: 2
  }
]

const replaceSpaceToEncodedPlusSign = param => _.chain(param).split(" ").join("%2B").value()

export default function Contents() {
  const [betDetail, setBetDetail] = useState({})
  const [mounted, setMounted] = useState(false)
  const p = useSearchParam("p")
  const s = useSearchParam("s")

  useMount(() => {
    if (p === null || s === null) {
      alert("error")
      return
    }
    axios
      .get(`/api/detail?p=${replaceSpaceToEncodedPlusSign(p)}&s=${replaceSpaceToEncodedPlusSign(s)}`)
      .then(res => {
        if (res?.data?.code === 0) {
          res.data?.data && setBetDetail(res.data.data)
        } else {
          alert("发生错误")
        }
      })
      .catch(e => {
        console.log(e)
        alert("发生错误")
      })
      .then(() => {
        setMounted(true)
      })
  })

  if (!mounted) return null

  return (
    <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} bordered labelStyle={{ width: 140 }}>
      {ITEM_LIST.map((item, index) => (
        <DescriptionItem key={index} label={item.label} span={item?.span || 1}>
          {_.get(betDetail, item.key) !== undefined ? (item.render ? item.render(_.get(betDetail, item.key), betDetail) : _.get(betDetail, item.key)) : null}
        </DescriptionItem>
      ))}
    </Descriptions>
  )
}
