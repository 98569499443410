import styled from "styled-components"

export const COLOR_MAP = {
  red: "#ff4d4f",
  blue: "#1890ff",
  gray: "#bfbfbf"
}

export const PageContainer = styled.div`
  padding: 20px;
`

export const UserIdText = styled.span`
  margin-right: 6px;
  color: ${({ color }) => color};
  white-space: nowrap;
`
