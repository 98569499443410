import "antd/dist/antd.min.css"
import { PageContainer } from "./components/styledComponents"
import { Contents } from "./components"

function App() {
  return (
    <div className="App">
      <PageContainer>
        <Contents />
      </PageContainer>
    </div>
  )
}

export default App
